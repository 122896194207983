import React from "react";

const MutedButton = ({ muted, setMuted }) => {
	return (
		<div className="absolute text-sm bottom-4 right-4 ...">
			<button
				onClick={() => setMuted(!muted)}
				className="text-white inline-flex items-center"
			>
				{muted ? (
					<>
						Click to unmute
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="21"
							height="19"
							fill="none"
							viewBox="0 0 21 19"
							className="ml-2"
						>
							{/* SVG path for unmute icon */}
						</svg>
					</>
				) : (
					<>
						Click to mute
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="21"
							height="19"
							fill="none"
							viewBox="0 0 21 19"
							className="ml-2"
						>
							{/* SVG path for mute icon */}
						</svg>
					</>
				)}
			</button>
		</div>
	);
};

export default MutedButton;

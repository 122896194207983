import React, { useState } from "react";
import PortableText from "react-portable-text";
import Container from "../components/Container/Container";
import MutedButton from "../components/Global/MutedButton";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/SEO/SEO";

import { graphql, Link } from "gatsby";
import InView from "../components/InView";

import Image from "gatsby-plugin-sanity-image";

// import React Player
import ReactPlayer from "react-player";

export const query = graphql`
	query OurFoundationQuery {
		site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
			defaultImage {
				asset {
					_id
				}
			}
		}
		sanityOurFoundation {
			title
			introductionTitle
			introduction
			hero {
				kind
				heroImage {
					...Image
					alt
				}
				heroVideoUrl
			}
			content {
				... on SanityFullBleedImage {
					_key
					_type
					image {
						...Image
						alt
						asset {
							_id
						}
					}
				}
				... on SanityImageTextBlock {
					_key
					_type
					description
					imageDirection
					imageType
					layout
					title
					link
					linkText
					media
					videoUrl
					image {
						...Image
						alt
						asset {
							_id
						}
					}
				}
				... on SanityRelatedNews {
					_key
					_type
					news {
						title
						_rawExcerpt
						mainImage {
							...Image
							alt
							asset {
								_id
							}
						}
						slug {
							current
						}
					}
				}
			}
		}
	}
`;

const OurFoundation = (props) => {
	const { data, errors } = props;
	const site = (data || {}).site;
	const [muted, setMuted] = useState(true);
	const [loaded, setLoaded] = useState(false);

	if (errors) {
		return (
			<>
				<GraphQLErrorList errors={errors} />
			</>
		);
	}

	return (
		<>
			<Seo
				title={data.sanityOurFoundation.title}
				description={data.sanityOurFoundation.description}
				image={
					data.sanityOurFoundation.hero.heroImage
						? data.sanityOurFoundation.hero.heroImage
						: site.defaultImage
				}
			/>
			<Container>
				<div className="border-b border-grey-default box-border">
					<div className="absolute hidden md:inline-block left-1/2 transform -translate-x-1/2 container top-20 z-10 text-white">
						<h1 className="font-serif text-[32px] leading-[1.2]">
							{data.sanityOurFoundation.title}
						</h1>
					</div>

					{data.sanityOurFoundation.hero.kind === "video" && (
						<div
							className={
								`hero-wrapper video-wrapper mb-4 md:mb-0 lazy ` +
								(loaded && " loaded")
							}
						>
							<ReactPlayer
								width="100vw"
								height="100vh"
								playing
								playsinline={true}
								loop
								muted
								url={data.sanityOurFoundation.hero.heroVideoUrl}
								onStart={() => setLoaded(true)}
							/>
						</div>
					)}

					{data.sanityOurFoundation.hero.kind === "image" && (
						<div className="hero-wrapper mb-4 md:mb-0">
							<Image
								{...data.sanityOurFoundation.hero.heroImage}
								width={1600}
								height={880}
								alt={data.sanityOurFoundation.hero.heroImage.alt}
								className={`lazy ` + (loaded && " loaded")}
								onLoad={() => setLoaded(true)}
							/>
						</div>
					)}

					<InView>
						<div className="border-b border-grey-default box-border pt-5 pb-16 md:py-0">
							<div className="container flex flex-col md:grid md:grid-cols-12 md:mt-10 md:mb-[120px]">
								<div className="md:col-span-3">
									<h2>{data.sanityOurFoundation.introductionTitle}</h2>
								</div>
								<div className="mt-2 md:mt-0 md:col-span-6">
									<h3 className="font-serif text-lg">
										{data.sanityOurFoundation.introduction}
									</h3>
								</div>
							</div>
						</div>
					</InView>

					{data.sanityOurFoundation.content.length > 0 &&
						data.sanityOurFoundation.content.map((content, i) => (
							<>
								{content._type === "imageTextBlock" && (
									<InView key={i}>
										<div className="border-b border-grey-default box-border">
											<div className="container flex flex-col md:grid md:grid-cols-12 py-7 md:py-0 md:mt-10 gap-6 md:gap-10">
												<div
													className={`flex flex-col md:pb-10 ${
														content.layout === "video" ? ` md:col-span-4` : ` `
													} ${
														content.layout === "two-thirds"
															? ` md:col-span-5`
															: ` `
													} ${
														content.layout === "one-quarter"
															? ` md:col-span-8`
															: ` `
													}${
														content.imageDirection === "image-right"
															? ` md:pr-10 order-1`
															: ` order-2 mt-4 md:mt-0`
													}`}
												>
													<h3 className="max-w-[350px]">{content.title}</h3>
													<div
														className={`mt-3 md:mt-auto ${
															content.layout === "two-thirds"
																? ` max-w-[520px]`
																: ` max-w-[680px]`
														}`}
													>
														<h2 className="font-serif text-lg mb-2">
															{content.description}
														</h2>
														{content.link && (
															<Link
																className="text-sm text-grey-base link"
																to={content.link}
															>
																{content.linkText}
															</Link>
														)}
													</div>
												</div>
												<div
													className={`md:pb-10 md:border-grey-default box-border ${
														content.layout === "video" ? ` md:col-span-8` : ` `
													} ${
														content.layout === "two-thirds"
															? ` md:col-span-7`
															: ` `
													} ${
														content.layout === "one-quarter"
															? ` md:col-span-4`
															: ` `
													} ${
														content.imageDirection === "image-right"
															? ` order-2 md:pl-10 md:border-l mt-10 md:mt-0`
															: ` order-1 md:pr-10 md:border-r`
													}`}
												>
													{content.media === "image" &&
														content.imageType === "landscape" && (
															<Image
																{...content.image}
																width={1000}
																height={680}
																alt={content.image.alt}
																className={`lazy ` + (loaded && " loaded")}
																onLoad={() => setLoaded(true)}
															/>
														)}
													{content.media === "image" &&
														content.imageType === "portrait" && (
															<Image
																{...content.image}
																width={605}
																height={880}
																alt={content.image.alt}
																className={
																	`max-w-[75%] md:max-w-[90%] max-h-[880px] w-auto lazy ` +
																	(loaded && " loaded")
																}
																onLoad={() => setLoaded(true)}
															/>
														)}
													{content.media === "video" && (
														<div
															className={
																`video-wrapper relative lazy ` +
																(loaded && " loaded")
															}
														>
															<ReactPlayer
																className="react-player"
																width="100%"
																height="100%"
																playing
																playsinline={true}
																loop
																muted={muted}
																config={{
																	file: {
																		attributes: {
																			autoPlay: true,
																			muted: true,
																		},
																	},
																}}
																url={content.videoUrl}
																onReady={() => setLoaded(true)}
															/>
															<MutedButton muted={muted} setMuted={setMuted} />
														</div>
													)}
												</div>
											</div>
										</div>
									</InView>
								)}

								{content._type === "fullBleedImage" && (
									<InView key={i}>
										<Image
											{...content.image}
											width={1400}
											height={770}
											alt={content.image.alt}
											className={`lazy ` + (loaded && " loaded")}
											onLoad={() => setLoaded(true)}
										/>
									</InView>
								)}

								{content._type === "relatedNews" && (
									<InView key={i}>
										<div className="container flex flex-col md:grid md:grid-cols-12 md:gap-10 md:mt-10">
											<div className="md:col-span-12 my-5 md:my-0 md:pb-10">
												<h2>Our foundation initiatives</h2>
											</div>
											<div className="hidden md:flex md:col-span-3 md:pb-10 md:border-r md:border-grey-default md:box-border"></div>
											{content.news.map((item) => (
												<div
													className="md:col-span-4 md:border-r md:border-grey-default md:box-border mb-10 md:mb-0"
													key={item._id}
												>
													<div className="md:pb-[120px] md:pr-10">
														{item.mainImage && item.mainImage.asset && (
															<Link
																className="overflow-hidden block"
																to={`/news/${item.slug.current}`}
															>
																<Image
																	{...item.mainImage}
																	width={600}
																	alt={item.mainImage.alt}
																	className={
																		`transition-transform duration-500 ease-in-out md:hover:transform md:hover:scale-[1.05] lazy ` +
																		(loaded && " loaded")
																	}
																	onLoad={() => setLoaded(true)}
																/>
															</Link>
														)}
														<h3 className="font-serif text-lg my-4 md:mt-10 md:mb-[15px]">
															{item.title}
														</h3>
														<div className="text-sm">
															{item._rawExcerpt && (
																<PortableText
																	content={item._rawExcerpt}
																	serializers={{
																		internalLink: ({ reference, children }) => {
																			const href = `/${reference._type}/${reference.slug.current}`;
																			return <Link to={href}>{children}</Link>;
																		},
																		link: ({ href, blank, children }) => {
																			return (
																				<a
																					href={href}
																					target={blank ? "_blank" : "_self"}
																					rel={blank ? "noreferrer" : undefined}
																				>
																					{children}
																				</a>
																			);
																		},
																	}}
																/>
															)}
														</div>
														<Link
															className="mt-3 text-sm text-grey-base link"
															to={`/news/${item.slug.current}`}
														>
															Read more
														</Link>
													</div>
												</div>
											))}
										</div>
									</InView>
								)}
							</>
						))}
				</div>
			</Container>
		</>
	);
};

export default OurFoundation;
